<template>
  <div class="homepage-community">
    <router-link
      to="/community"
      class="homepage-community__banner"
      :style="linkStyles"
    >
      <img
        src="/wp-content/themes/hu2/images/community-logo.png?q=0"
        alt="Site Logo"
        loading="lazy"
        class="homepage-community__site-logo"
      >
      <h2 class="homepage-community__heading">Explore the Community</h2>
      <p class="homepage-community__text">Looking for support? Have a question?</p>
      <p class="homepage-community__text">The community is here for you.</p>
      <p class="homepage-community__cta">
        Join us
        <icon-component
          class="homepage-community__icon"
          name="caret"
          title="caret icon"
        />
      </p>
    </router-link>
    <ad-slot mapping="HomeCommunity" />
  </div>
</template>

<script>
import AdSlot from '@/components/Ads/AdSlot.vue';

export default {
  name: 'HomepageCommunity',
  components: {
    AdSlot,
  },
  computed: {
    linkStyles() {
      return {
        'background-image': 'url("/wp-content/themes/hu2/images/community-background.png")',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_homepage-community';
</style>

<docs>
</docs>
